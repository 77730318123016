import React from 'react';
import { useTranslation } from 'react-i18next';
import AudioPlayer from './AudioPlayer';

const getExampleAudioUrl = (part, topic) => {
  let exampleTopic;
  
  if (topic === 'random') {
    // Default topics for random selection
    const defaultRandomTopics = {
      1: 'hometown1',
      2: 'important_possession1',
      3: 'social_media1'
    };
    exampleTopic = defaultRandomTopics[part];
  } else {
    // If topic is not random, add '1' to the end of the topic
    exampleTopic = topic + '1';
  }

  if (!exampleTopic) return '';
  
  return `https://fluentious.s3.eu-west-3.amazonaws.com/audio/part${part}_${exampleTopic}.mp3`;
};

const Instructions = ({ part, topic }) => {
  const { t } = useTranslation();

  const getInstructions = () => {
    const topicText = topic === 'random' 
      ? t('a_random_topic').toLowerCase()
      : topic 
        ? t(`topic_${topic}`).toLowerCase() 
        : t('selected_topic').toLowerCase();

    switch (part) {
      case 1:
        return t('part_1_instructions', { topic: topicText });
      case 2:
        return t('part_2_instructions', { topic: topicText });
      case 3:
        return t('part_3_instructions', { topic: topicText });
      default:
        return '';
    }
  };

  const getTopicText = () => {
    return topic === 'random'
      ? t('random_topic')
      : topic
        ? t(`topic_${topic}`)
        : t('selected_topic');
  };

  return (
    <div style={{ maxWidth: '675px', margin: '0 auto', marginBottom: '2rem' }}>
      <div 
        className="d-flex flex-column align-items-center gap-3 px-3 py-3"
        style={{
          background: '#f8f9fa',
          borderRadius: '20px',
          transition: 'all 0.3s ease',
          border: '1px solid #dee2e6',
          color: '#6c757d',
          fontSize: '0.9rem',
          textAlign: 'center'
        }}
      >
        <div>
          {getInstructions()}
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <div style={{ fontSize: '0.85rem' }}>
            {t('band_9_example', { topic: getTopicText() })}: <AudioPlayer url={getExampleAudioUrl(part, topic)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
