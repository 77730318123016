import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const TopicSelector = ({ part, selectedTopic, onSelect }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  
  // Set random as default topic when part changes or component mounts
  useEffect(() => {
    if (!selectedTopic) {
      onSelect('random');
    }
  }, [part, selectedTopic, onSelect]);

  const isTopicLocked = (topicId) => {
    return topicId !== 'random' && user?.role === 'basic';
  };

  const handleTopicClick = (topic) => {
    if (isTopicLocked(topic.id)) {
      navigate('/upgrade');
    } else {
      onSelect(topic.id);
    }
  };

  const getTopicsForPart = (part) => {
    switch (part) {
      case 1:
        return [
          { id: 'random', name: t('topic_random'), icon: 'bi-shuffle' },
          { id: 'hometown', name: t('topic_hometown'), icon: 'bi-house-heart' },
          { id: 'work_or_study', name: t('topic_work_or_study'), icon: 'bi-briefcase' },
          { id: 'free_time', name: t('topic_free_time'), icon: 'bi-controller' },
          { id: 'family', name: t('topic_family'), icon: 'bi-people' },
          { id: 'accommodation', name: t('topic_accommodation'), icon: 'bi-building' },
          { id: 'food', name: t('topic_food'), icon: 'bi-cup-hot' },
          { id: 'travel', name: t('topic_travel'), icon: 'bi-airplane' },
          { id: 'weather', name: t('topic_weather'), icon: 'bi-cloud-sun' },
          { id: 'technology', name: t('topic_technology'), icon: 'bi-laptop' }
        ];
      case 2:
        return [
          { id: 'random', name: t('topic_random'), icon: 'bi-shuffle' },
          { id: 'person_you_admire', name: t('topic_person_you_admire'), icon: 'bi-person-heart' },
          { id: 'memorable_event', name: t('topic_memorable_event'), icon: 'bi-calendar-event' },
          { id: 'favorite_place', name: t('topic_favorite_place'), icon: 'bi-geo-alt' },
          { id: 'childhood_memory', name: t('topic_childhood_memory'), icon: 'bi-hourglass-split' },
          { id: 'useful_skill', name: t('topic_useful_skill'), icon: 'bi-trophy' },
          { id: 'special_celebration', name: t('topic_special_celebration'), icon: 'bi-gift' },
          { id: 'important_possession', name: t('topic_important_possession'), icon: 'bi-box-seam' },
          { id: 'favorite_hobby', name: t('topic_favorite_hobby'), icon: 'bi-heart' },
          { id: 'recent_trip', name: t('topic_recent_trip'), icon: 'bi-compass' }
        ];
      case 3:
        return [
          { id: 'random', name: t('topic_random'), icon: 'bi-shuffle' },
          { id: 'education', name: t('topic_education'), icon: 'bi-book' },
          { id: 'technology', name: t('topic_technology'), icon: 'bi-cpu' },
          { id: 'environment', name: t('topic_environment'), icon: 'bi-tree' },
          { id: 'work_life', name: t('topic_work_life'), icon: 'bi-briefcase-fill' },
          { id: 'social_media', name: t('topic_social_media'), icon: 'bi-chat-dots' },
          { id: 'urbanization', name: t('topic_urbanization'), icon: 'bi-buildings' },
          { id: 'globalization', name: t('topic_globalization'), icon: 'bi-globe' },
          { id: 'health', name: t('topic_health'), icon: 'bi-heart-pulse' },
          { id: 'transportation', name: t('topic_transportation'), icon: 'bi-car-front' }
        ];
      default:
        return [];
    }
  };

  const topics = getTopicsForPart(part);

  return (
    <div className="topic-selector" style={{ marginBottom: '2rem' }}>
      <div className="container" style={{ maxWidth: '700px' }}>
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          {topics.map(topic => (
            <div 
              key={topic.id}
              className={`btn ${isTopicLocked(topic.id) ? 'btn-outline-danger' : 'btn-outline-primary'} rounded-pill px-2 py-1 ${selectedTopic === topic.id ? 'active' : ''}`}
              onClick={() => handleTopicClick(topic)}
              style={{ 
                cursor: 'pointer',
                fontSize: '0.8rem',
                transition: 'all 0.2s ease',
                position: 'relative'
              }}
            >
              <i className={`bi ${isTopicLocked(topic.id) ? 'bi-lock-fill' : topic.icon} me-1`}></i>
              {topic.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopicSelector; 