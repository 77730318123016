import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import logo from '../assets/logo_with_mic_black.png'; // Import your logo
// import videoFile from '../assets/intro.mp4'; // Import your video
// import thumbnail from '../assets/intro_poster.png';
import USPs from './USPs';

const Hero = ({ user }) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="px-4 mt-3 mt-4 pt-2 mt-lg-5 text-center">
      <img 
        src={logo} 
        alt="Logo" 
        className="img-fluid mb-4 mt-3" 
        style={{ 
          maxWidth: '475px', 
          width: '90%', 
          height: 'auto',
        }}
      />
      <div className="col-lg-6 col-md-8 mx-auto mt-4 mb-4">
        <p className="lead display-6 mb-3" 
          style={{ 
            fontSize: 'clamp(2.4rem, 5vw, 3.3rem)'  // Responsive font size between 2rem and 3.5rem
          }}>
          {t('subtitle')}
        </p>
      </div>
      <USPs />
      <div className="overflow-hidden">
        {/* <div className="container pb-2 mb-3 px-0">
          <video
            src={videoFile}
            className="img-fluid border rounded-3 shadow-lg"
            alt="Fluentious"
            style={{ width: '100%', height: 'auto', maxWidth: '700px' }} // Max width for desktop, full width for mobile
            controls
            loading="lazy"
            poster={thumbnail}
          >
            Your browser does not support the video tag.
          </video>
        </div> */}
        <div className="d-grid gap-3 d-sm-flex justify-content-sm-center mb-5 pb-3 mt-4">
          <Link 
            to="/signup" 
            className="btn btn-primary btn-lg px-4 px-5 fs-4 rounded-pill"
          >
            {t('sign_up_for_free')}
          </Link>
          <Link 
            to="/#features" 
            className="btn btn-outline-primary btn-lg px-5 fs-4 rounded-pill"
          >
            {t('learn_more')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
