import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/ExercisesPage.module.css';
import Feedback from '../components/Feedback';
import logo from '../assets/logo_with_mic_black.png';

const HistoryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedExercise, setExpandedExercise] = useState(null);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/exercises/history', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setHistory(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
    });
  };

  const formatTopic = (topic) => {
    // Remove number from end and prepend "topic_"
    return 'topic_' + topic.replace(/\d+$/, '');
  };

  const colors = {
    overall: { border: 'rgb(47, 86, 188)', background: 'rgba(47, 86, 188, 0.1)' },
    fluency: { border: 'rgb(75, 192, 192)', background: 'rgba(75, 192, 192, 0.1)' },
    vocabulary: { border: 'rgb(255, 159, 64)', background: 'rgba(255, 159, 64, 0.1)' },
    grammar: { border: 'rgb(153, 102, 255)', background: 'rgba(153, 102, 255, 0.1)' },
    pronunciation: { border: 'rgb(255, 99, 132)', background: 'rgba(255, 99, 132, 0.1)' }
  };

  const getScores = (feedback) => {
    if (!feedback) return null;
    return {
      overall: feedback.overall?.score ?? null,
      fluency: feedback.fluency?.score ?? null,
      vocabulary: feedback.vocabulary?.score ?? null,
      grammar: feedback.grammar?.score ?? null,
      pronunciation: feedback.pronunciation?.score ?? null
    };
  };

  const handleExerciseClick = (exerciseId) => {
    setExpandedExercise(expandedExercise === exerciseId ? null : exerciseId);
  };

  return (
    <div className="container mt-5 px-4">
      <div className="text-center mb-5">
        <img
          src={logo}
          alt="Logo"
          className="img-fluid"
          style={{
            height: "60px",
            width: "auto",
            objectFit: "contain",
          }}
        />
        <div className="mt-3 fs-3" style={{ color: "#aaa" }}>{t('subtitle')}</div>
      </div>
      
      <h1 className="text-center mb-4">{t('exercise_history')}</h1>
      <div className="col-md-9 mx-auto">
        <div className="card p-3 mb-4 shadow">
          {history.length === 0 ? (
            <div className="text-center mt-4">
              <p>{t('no_exercises_completed')}</p>
              <Link to="/" className="btn btn-primary">
                {t('start_practicing')}
              </Link>
            </div>
          ) : (
            <div className="list-group">
              {history.map((exercise) => (
                <div key={exercise._id}>
                  <div
                    className="list-group-item list-group-item-action p-4 cursor-pointer"
                    style={{ 
                      cursor: 'pointer', 
                      textAlign: 'center',
                      borderBottom: '1px solid #dee2e6',
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0
                    }}
                    onClick={() => handleExerciseClick(exercise._id)}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <p className="mb-2 text-muted small">
                        {formatDate(exercise.createdAt)}
                      </p>
                      <h5 className="mb-3 d-flex align-items-center justify-content-center gap-2">
                        <span>
                          {t('part')} {exercise.part}
                        </span>
                        <span className="text-secondary">-</span>
                        {t(formatTopic(exercise.topic))}
                      </h5>
                      {exercise.feedback && (
                        <div className="d-flex gap-2 flex-wrap mt-1 justify-content-center">
                          {Object.entries(getScores(exercise.feedback)).map(([key, value]) => (
                            value !== null && (
                              <span 
                                key={key} 
                                className="badge fw-normal" 
                                style={{
                                  backgroundColor: colors[key].background,
                                  color: colors[key].border,
                                  border: `1px solid ${colors[key].border}`
                                }}
                              >
                                {t(key)}: {parseFloat(value).toFixed(1)}
                              </span>
                            )
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {expandedExercise === exercise._id && exercise.feedback && (
                    <div className="list-group-item px-0 py-3 border-0">
                      <Feedback 
                        feedback={exercise.feedback}
                        pronunciations={new Map()}
                        conversationAudioUrl={exercise.audioUrl}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          <button
            onClick={() => navigate('/')}
            className="btn btn-primary d-inline-flex align-items-center gap-2 px-4 py-2"
            style={{
              borderRadius: "30px",
              transition: "all 0.3s ease",
              fontSize: "1.1rem",
            }}
          >
            <i className="bi bi-arrow-left"></i>
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HistoryPage; 