import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import constants from '../config/constants';

const FAQ = () => {
  const { t } = useTranslation(); // Initialize translation

  const faqs = [
    {
      question: t('faq_question_practice'),
      answer: t('faq_answer_practice'),
    },
    {
      question: t('faq_question_ai'),
      answer: t('faq_answer_ai'),
    },
    {
      question: t('faq_question_progress'),
      answer: t('faq_answer_progress'),
    },
    {
      question: t('faq_question_topics'),
      answer: t('faq_answer_topics', {
        topicCount: constants.topicCount,
      }),
    },
    {
      question: t('faq_question_basic'),
      answer: t('faq_answer_basic', { 
        total: constants.exerciseLimits.basic.part1 + constants.exerciseLimits.basic.part2 + constants.exerciseLimits.basic.part3,
        perPart: constants.exerciseLimits.basic.part1,
      }),
    },
    {
      question: t('faq_question_premium'),
      answer: t('faq_answer_premium', { 
        total: constants.exerciseLimits.premium.part1 + constants.exerciseLimits.premium.part2 + constants.exerciseLimits.premium.part3,
        perPart: constants.exerciseLimits.premium.part1,
        topicCount: constants.topicCount
      }),
    },
    {
      question: t('faq_question_8'),
      answer: t('faq_answer_8'),
    },
    {
      question: t('faq_question_9'),
      answer: t('faq_answer_9'),
    },
  ];

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('faq_title')}</h2>
        <p className="fs-5 text-body-secondary">{t('faq_subtitle')}</p>
      </div>
      <div className="accordion" id="faqAccordion">
        {faqs.map((faq, index) => (
          <div className="accordion-item" key={index}>
            <h3 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
                style={{ backgroundColor: '#fcfcfc' }}
              >
                {faq.question}
              </button>
            </h3>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${index}`}
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
