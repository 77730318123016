import React, { useState, useEffect } from 'react';

// Static reference shared between all AudioPlayer instances
let currentAudio = null;
let audioStoppedCallbacks = new Set();

// Export the stop function separately
export const stopAllAudio = () => {
  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0;
    currentAudio = null;
    // Notify all AudioPlayer instances that audio was stopped to update their state
    audioStoppedCallbacks.forEach(callback => callback());
  }
};

const AudioPlayer = ({ url, base64 }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Register this instance's callback
    const callback = () => setIsPlaying(false);
    audioStoppedCallbacks.add(callback);

    // Cleanup on unmount
    return () => {
      audioStoppedCallbacks.delete(callback);
    };
  }, []);

  if (!url && !base64) return null;

  const toggleAudio = () => {
    stopAllAudio();

    if (!isPlaying) {
      const newAudio = new Audio(base64 ? `data:audio/mp3;base64,${base64}` : url);
      currentAudio = newAudio;
      
      newAudio.play().catch(error => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
        currentAudio = null;
      });

      newAudio.onended = () => {
        setIsPlaying(false);
        currentAudio = null;
      };

      setIsPlaying(true);
    }
  };
  
  return (
    <button 
      onClick={toggleAudio}
      className="btn btn-link p-0"
      style={{ 
        cursor: 'pointer',
        color: 'white',
        transition: 'all 0.2s ease',
        border: 'none',
        background: '#2f56bc',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        minWidth: '24px',
        minHeight: '24px',
        lineHeight: 1,
        borderRadius: '50%',
        marginLeft: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: 0,
        textDecoration: 'none'
      }}
      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      title={isPlaying ? "Stop example interview" : "Play example interview"}
    >
      <i className={`bi bi-${isPlaying ? 'stop-fill' : 'volume-up-fill'}`} style={{ 
        fontSize: '0.875rem',
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none'
      }}></i>
    </button>
  );
};

export default AudioPlayer;