import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import constants from '../config/constants';

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('pricing_title')}</h2>
        <p className="fs-5 text-body-secondary">
          {t('pricing_subtitle')}
        </p>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 text-center mb-3">
        {/* Basic Plan */}
        <div className="col d-flex">
          <div className="card w-100 rounded-3 shadow-sm h-100 d-flex flex-column">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">{t('pricing_basic_plan')}</h4>
            </div>
            <div className="card-body d-flex flex-column">
              {/* Price Section */}
              <div className="mb-2 mt-2 d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '90px' }}>
                <h1 className="card-title pricing-card-title fw-normal" style={{ fontSize: '2.5rem' }}>{t('pricing_basic_price')}</h1>
              </div>
              {/* Features Section */}
              <div className="d-flex justify-content-center px-2">
                <ul className="list-unstyled my-4 mb-4 d-flex flex-column" style={{ fontSize: '1.1rem' }}>
                  <li className="mb-3 text-start">
                    <i className="bi bi-check2 me-3"></i>
                    {t('basic_practice_sessions', { 
                      total: constants.exerciseLimits.basic.part1 + constants.exerciseLimits.basic.part2 + constants.exerciseLimits.basic.part3,
                      perPart: constants.exerciseLimits.basic.part1 
                    })}
                  </li>
                  <li className="mb-3 text-start">
                    <i className="bi bi-check2 me-3"></i>
                    {t('pricing_random_topics')}
                  </li>
                </ul>
              </div>
              {/* CTA Section */}
              <div className="mt-auto">
                <Link to="/signup" className="w-100 btn btn-lg btn-outline-primary rounded-pill">{t('pricing_basic_button')}</Link>
              </div>
            </div>
          </div>
        </div>
        {/* Premium Plan */}
        <div className="col d-flex">
          <div className="card w-100 rounded-3 shadow-sm border-primary h-100 d-flex flex-column">
            <div className="card-header py-3 text-bg-primary border-primary position-relative">
              <h4 className="my-0 fw-normal text-light">{t('pricing_premium_plan')}</h4>
              <span className="position-absolute badge rounded-pill bg-danger" 
                    style={{ 
                      fontSize: '1.2rem',
                      fontWeight: '700',
                      padding: '0.9rem 1.3rem',
                      top: '-15px',
                      right: '-20px',
                      transform: 'rotate(12deg)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.25)'
                    }}>
                {t('pricing_premium_discount_percentage', { percentage: constants.prices.discount })}
              </span>
            </div>
            <div className="card-body d-flex flex-column">
              {/* Updated Price Section */}
              <div className="mb-2 mt-2 d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '90px' }}>
                <h1 className="card-title pricing-card-title fw-normal mb-1 d-flex flex-wrap justify-content-center" style={{ fontSize: '2.5rem' }}>
                  <div>
                    <span className="text-decoration-line-through me-2" style={{ color: '#aaa' }}>{constants.prices.standard}</span>
                  </div>
                  <div>
                    {constants.prices.discounted}<sup style={{ fontSize: '1.5rem' }}>*</sup>
                    <small className="text-body-secondary fw-light">{t('pricing_premium_duration')}</small>
                  </div>
                </h1>
                <p className="text-danger mb-0 fs-5">{t('pricing_premium_launch_offer')}</p>
              </div>
              {/* Features Section for Premium */}
              <div className="d-flex justify-content-center px-2">
                <ul className="list-unstyled my-4 mb-4 d-flex flex-column" style={{ fontSize: '1.1rem' }}>
                  <li className="mb-3 text-start">
                    <i className="bi bi-check2 me-3"></i>
                    {t('premium_practice_sessions', { 
                      total: constants.exerciseLimits.premium.part1 + constants.exerciseLimits.premium.part2 + constants.exerciseLimits.premium.part3,
                      perPart: constants.exerciseLimits.premium.part1 
                    })}
                    <small className="text-body-secondary">{t('pricing_premium_duration')}</small>
                  </li>
                  <li className="mb-3 text-start">
                    <i className="bi bi-check2 me-3"></i>
                    {t('pricing_topic_selection', { count: constants.topicCount })}
                  </li>
                  <li className="mb-3 text-start">
                    <i className="bi bi-check2 me-3"></i>
                    {t('pricing_perfect_examples')}
                  </li>
                  <li className="mb-3 text-start">
                    <i className="bi bi-check2 me-3"></i>
                    {t('pricing_progress_tracking')}
                  </li>
                </ul>
              </div>
              {/* Price Note */}
              <div className="mb-3">
                <p className="small mb-0" style={{ color: '#999' }}>{t('pricing_premium_offer_note', { discounted: constants.prices.discounted, standard: constants.prices.standard })}</p>
              </div>
              {/* CTA Section */}
              <div className="mt-auto">
                <Link to="/signup" className="w-100 btn btn-lg btn-primary rounded-pill">{t('pricing_premium_button')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
