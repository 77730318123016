import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import basicImage from '../assets/subscription_basic.png';
import premiumImage from '../assets/subscription_premium.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      window.location.href = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL + '?prefilled_email=' + encodeURIComponent(user.email);
    } catch (error) {
      console.error('Error redirecting to billing portal:', error);
      setError(t('error_redirect_billing'));
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = () => {
    navigate('/upgrade');
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">{t('settings')}</h1>
      
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              {/* User Info Section */}
              <div className="text-center my-3">
                <p className="fs-4 mb-1">{user.name}</p>
                <p className="text-muted mb-4 fs-6">{user.email}</p>
                
                {/* Subscription Status */}
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img 
                    src={user.role === 'premium' ? premiumImage : basicImage} 
                    alt={t(`${user.role}_subscription`)} 
                    style={{ height: '20px' }}
                    className="mb-3" 
                  />
                  {user.role === 'premium' ? (
                    <button
                      type="button"
                      className="btn btn-primary mx-md-3 rounded-pill px-5"
                      onClick={handleManageSubscription}
                      disabled={loading}
                    >
                      {t('manage_subscription')}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success mx-md-3 rounded-pill px-5"
                      onClick={handleSubscribe}
                      disabled={loading}
                    >
                      {t('upgrade')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-4 mb-4 text-center">
        <button 
          className="btn btn-primary rounded-pill px-5" 
          onClick={() => navigate('/')}
          style={{ fontSize: '1.15rem' }}
        >
          <i className="bi bi-reply me-1"></i> {t('back')}
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
