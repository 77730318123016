import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PerformanceChart from '../components/PerformanceChart';
import logo from "../assets/logo_with_mic_black.png";

const ProgressPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="container mt-4">
      <div className="container mt-5 px-4">
        <div className="text-center mb-5">
          <img
            src={logo}
            alt="Logo"
            className="img-fluid"
            style={{
              height: "60px",
              width: "auto",
              objectFit: "contain",
            }}
          />
          <div className="mt-3 fs-3" style={{ color: "#aaa" }}>{t('subtitle')}</div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-10">
            <h1 className="mb-4 text-center">{t('your_progress')}</h1>
            <PerformanceChart />
            
            <div className="d-flex justify-content-center mt-5 mb-5">
              <button
                onClick={() => navigate('/')}
                className="btn btn-primary d-inline-flex align-items-center gap-2 px-4 py-2"
                style={{
                  borderRadius: "30px",
                  transition: "all 0.3s ease",
                  fontSize: "1.1rem",
                }}
              >
                <i className="bi bi-arrow-left"></i>
                {t('back')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressPage; 