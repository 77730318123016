export class AudioRecorder {
  constructor({ sampleRate = 24000 } = {}) {
    this.mediaRecorder = null;
    this.stream = null;
    this.audioContext = null;
    this.analyser = null;
    this.dataCallback = null;
    this.sampleRate = sampleRate;
    this.processor = null;
  }

  async begin() {
    try {
      // Request microphone access
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        }
      });

      // Create audio context and analyzer
      this.audioContext = new AudioContext();

      const source = this.audioContext.createMediaStreamSource(this.stream);
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 2048;

      // Create ScriptProcessor for real-time processing
      this.processor = this.audioContext.createScriptProcessor(2048, 1, 1);
      
      // Connect the audio nodes
      source.connect(this.analyser);
      this.analyser.connect(this.processor);
      this.processor.connect(this.audioContext.destination);

      return true;
    } catch (error) {
      console.error('ModernRecorder begin error:', error);
      this.quit();
      throw error;
    }
  }

  async record(callback) {
    if (!this.processor) {
      throw new Error('Recorder not initialized. Call begin() first.');
    }

    this.dataCallback = callback;

    // Process audio data in real-time
    this.processor.onaudioprocess = (e) => {
      // Check if processor is still active before processing
      if (!this.processor || !this.audioContext) {
        return;
      }

      const inputData = e.inputBuffer.getChannelData(0);
      
      // Resample the audio to target sample rate
      const resampledData = this.resampleAudio(inputData, 
        this.audioContext.sampleRate, 
        this.sampleRate
      );
      
      // Convert Float32Array to Int16Array for compatibility
      const intData = new Int16Array(resampledData.length);
      for (let i = 0; i < resampledData.length; i++) {
        // Convert Float32 to Int16
        const s = Math.max(-1, Math.min(1, resampledData[i]));
        intData[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
      }

      // Send the data through the callback
      if (this.dataCallback) {
        this.dataCallback({
          mono: intData.buffer,
          raw: intData.buffer
        });
      }
    };

    return true;
  }

  resampleAudio(audioData, originalSampleRate, targetSampleRate) {
    if (originalSampleRate === targetSampleRate) {
      return audioData;
    }

    const ratio = originalSampleRate / targetSampleRate;
    const newLength = Math.round(audioData.length / ratio);
    const result = new Float32Array(newLength);

    for (let i = 0; i < newLength; i++) {
      const position = i * ratio;
      const index = Math.floor(position);
      const fraction = position - index;

      // Linear interpolation
      if (index + 1 < audioData.length) {
        result[i] = audioData[index] * (1 - fraction) + audioData[index + 1] * fraction;
      } else {
        result[i] = audioData[index];
      }
    }

    return result;
  }

  quit() {
    // Remove the audio processing callback first
    if (this.processor) {
      this.processor.onaudioprocess = null;
      this.processor.disconnect();
      this.processor = null;
    }
    
    if (this.analyser) {
      this.analyser.disconnect();
      this.analyser = null;
    }

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }

    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }

    this.dataCallback = null;
    return true;
  }
}