import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const ReportIssueButton = ({ part, topic }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issueText, setIssueText] = useState("");
  const [issueStatus, setIssueStatus] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
    setIssueStatus(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (issueStatus === "success") {
      setIssueText("");
    }
  };

  const handleIssueChange = (event) => {
    setIssueText(event.target.value);
  };

  const submitIssue = async () => {
    const trimmedMessage = issueText.trim();
    
    if (!trimmedMessage) {
      setIssueStatus("empty");
      return;
    }

    if (trimmedMessage.length < 10) {
      setIssueStatus("issue_too_short");
      return;
    }

    if (trimmedMessage.length > 2000) {
      setIssueStatus("issue_too_long");
      return;
    }

    try {
      const response = await axios.post('/api/issues', {
        part,
        topic,
        message: trimmedMessage
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 200) {
        setIssueStatus("success");
        setIssueText("");
      }
    } catch (error) {
      console.error('Error submitting issue:', error);
      setIssueStatus("error");
    }
  };

  return (
    <>
      <div>
        <button
          onClick={openModal}
          className="btn btn-link p-0"
          style={{ 
            fontSize: '0.9rem',
            textDecoration: 'none',
            color: '#dc3545', // Bootstrap's danger color
          }}
          onMouseEnter={(e) => e.target.style.color = '#b02a37'} // Darker red on hover
          onMouseLeave={(e) => e.target.style.color = '#dc3545'} // Back to original color
        >
          {t('report_issue')}
        </button>
      </div>

      {isModalOpen && (
        <>
          <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{t('report_issue')}</h5>
                  <button type="button" className="btn-close" onClick={closeModal}></button>
                </div>
                <div className="modal-body">
                  {issueStatus === "success" ? (
                    <div className="text-success text-center">
                      {t('issue_success')}
                    </div>
                  ) : (
                    <>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows="4"
                          value={issueText}
                          onChange={handleIssueChange}
                          placeholder={t('report_issue_placeholder')}
                        ></textarea>
                      </div>
                      {issueStatus === "empty" && (
                        <div className="text-danger mt-2">{t('issue_empty')}</div>
                      )}
                      {issueStatus === "error" && (
                        <div className="text-danger mt-2">{t('issue_error')}</div>
                      )}
                      {issueStatus === "issue_too_short" && (
                        <div className="text-danger mt-2">{t('issue_too_short')}</div>
                      )}
                      {issueStatus === "issue_too_long" && (
                        <div className="text-danger mt-2">{t('issue_too_long')}</div>
                      )}
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  {issueStatus === "success" && (
                    <button type="button" className="btn btn-primary" onClick={closeModal}>
                      {t('close')}
                    </button>
                  )}
                  {issueStatus !== "success" && (
                    <>
                      <button type="button" className="btn btn-secondary" onClick={closeModal}>
                        {t('close')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={submitIssue}
                      >
                        {t('submit')}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReportIssueButton; 