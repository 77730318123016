import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import SettingsPage from './pages/SettingsPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, AuthContext } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import VerifyEmailPage from './pages/VerifyEmailPage';
import UpgradePage from './pages/UpgradePage';
// import PremiumRoute from './components/PremiumRoute';
import FeedbackButton from './components/FeedbackButton';
import './styles/App.css';
// import Exam from './components/Exam';
// import ExamsPage from './pages/ExamsPage';
// import IeltsExam from './components/IeltsExam';
// import ExercisesPage from './pages/ExercisesPage';
import Exercise from './components/Exercise';
import HomePage from './pages/HomePage';
import ExercisesPage from './pages/ExercisesPage';
import AdminPage from './pages/AdminPage';
import Setup from './components/Setup';
import FeedbackPage from './pages/FeedbackPage';
import PracticePage from './pages/PracticePage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Part1Page from './pages/Part1Page';
import Part2Page from './pages/Part2Page';
import Part3Page from './pages/Part3Page';
import ProgressPage from './pages/ProgressPage';
import HistoryPage from './pages/HistoryPage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<><LoginPage /><FeedbackButton /></>} />
          <Route path="/signup" element={<><SignupPage /><FeedbackButton /></>} />
          <Route path="/verify-email" element={<><VerifyEmailPage /><FeedbackButton /></>} />
          <Route path="/forgot-password" element={<><ForgotPasswordPage /><FeedbackButton /></>} />
          <Route path="/reset-password" element={<><ResetPasswordPage /><FeedbackButton /></>} />
          <Route path="/*" element={<MainLayout />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const MainLayout = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex-grow-1">
        <Routes>
          { !user && <Route path="/" element={<LandingPage />} />}
          { user && <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />}
          {/* <Route path="/practice" element={<PrivateRoute><PracticePage /></PrivateRoute>} /> */}
          {/* <Route path="/exercises" element={<PrivateRoute><ExercisesPage /></PrivateRoute>} /> */}
          <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
          <Route path="/upgrade" element={<PrivateRoute><UpgradePage /></PrivateRoute>} />
          <Route path="/admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} />
          {/* <Route path="/feedback" element={<PrivateRoute><FeedbackPage /></PrivateRoute>} /> */}
          <Route path="/part-1" element={<PrivateRoute><Part1Page /></PrivateRoute>} />
          <Route path="/part-2" element={<PrivateRoute><Part2Page /></PrivateRoute>} />
          <Route path="/part-3" element={<PrivateRoute><Part3Page /></PrivateRoute>} />
          <Route path="/progress" element={<PrivateRoute><ProgressPage /></PrivateRoute>} />
          <Route path="/history" element={<PrivateRoute><HistoryPage /></PrivateRoute>} />
          {/* <Route path="/:section/:exercise" element={<PrivateRoute><Exercise /></PrivateRoute>} /> */}
        </Routes>
      </div>
      <Footer />
      <FeedbackButton />
      {/* {user && !user.hasCompletedSetup && <Setup />} */}
    </div>
  );
};

export default App;
