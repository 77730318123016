import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import Transcript from "../components/Transcript";
import Feedback from "../components/Feedback";
import CueCard from "../components/CueCard";
import NotePad from "../components/NotePad";
import Timer from "../components/Timer";
import { useRealtime } from "../hooks/useRealtime";
import logo from "../assets/logo_with_mic_black.png";
import PerformanceChart from "../components/PerformanceChart";
import TopicSelector from '../components/TopicSelector';
import LoadingText from '../components/LoadingText';
import Error from '../components/Error';
import Instructions from '../components/Instructions';
import { stopAllAudio } from '../components/AudioPlayer';
import { AuthContext } from '../context/AuthContext';
import ReportIssueButton from '../components/ReportIssueButton';

const PartIndicator = ({ currentPart, onPartSelect, remaining }) => {
  const { t } = useTranslation();
  const parts = [
    { num: 1, name: t('part_1_name') },
    { num: 2, name: t('part_2_name') },
    { num: 3, name: t('part_3_name') }
  ];

  return (
    <div className="mb-4" style={{ maxWidth: '800px', margin: '0 auto' }}>
      <div className="d-flex justify-content-center align-items-center gap-3 gap-md-4">
        {parts.map(({ num, name }) => (
          <div key={num} className="col-auto col-md-3 text-center">
            <button
              className={`btn ${currentPart === num ? 'btn-primary' : 'btn-outline-primary'} d-inline-flex align-items-center justify-content-center gap-1 gap-md-2 px-1 px-md-4 py-2 py-md-3 w-100 fs-6 fs-md-5`}
              onClick={() => onPartSelect(num)}
              style={{
                color: currentPart === num ? "white" : undefined,
                borderRadius: "30px",
                transition: "all 0.3s ease",
              }}
            >
              <div className="d-flex align-items-center gap-1">
                <span>{t('part')} {num}</span>
                <span className="d-none d-md-inline">: {name}</span>
              </div>
            </button>
            {remaining && (
              <div className="mt-1 mt-md-2 small">
                <span style={{ color: '#999' }}>
                  {t('remaining_exercises', { count: remaining[`part${num}`] })}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const UpgradeButton = ({ currentPart }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center justify-content-center mb-3">
      <button
        onClick={() => navigate('/upgrade')}
        className="btn btn-danger shadow d-inline-flex align-items-center justify-content-center gap-2 px-4 py-3 w-100"
        style={{
          color: "white",
          borderRadius: "30px",
          transition: "all 0.3s ease",
          maxWidth: "300px",
          fontSize: "1.1rem",
        }}
      >
        <span>{t('upgrade_for_part', { part: currentPart })}</span>
        <span>→</span>
      </button>
    </div>
  );
};

const StartButton = ({ onClick }) => {
  const { t } = useTranslation();
  
  return (
    <div className="d-flex align-items-center justify-content-center mb-2">
      <button
        onClick={onClick}
        className="btn btn-success shadow d-inline-flex align-items-center justify-content-center px-4 py-3 w-100"
        style={{
          color: "white",
          borderRadius: "30px",
          transition: "all 0.3s ease",
          maxWidth: "300px",
          fontSize: "1.1rem",
        }}
      >
        <span>{t('begin')}</span>
      </button>
    </div>
  );
};

const HomePage = () => {
  const { t } = useTranslation();
  const [currentPart, setCurrentPart] = useState(1);
  const [isPreparing, setIsPreparing] = useState(false);
  const [remaining, setRemaining] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { refreshUser } = useContext(AuthContext);

  const {
    isConnected,
    feedback,
    setFeedback,
    cueCard,
    isWaitingForFeedback,
    feedbackError,
    connect,
    disconnect,
    requestFeedback,
    getCueCard,
    pronunciations,
    conversationAudioUrl,
  } = useRealtime();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('upgrade_success') === 'true') {
      setShowSuccessMessage(true);
      refreshUser();
      // Clear the URL parameter
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [refreshUser]);

  const fetchRemaining = async () => {
    try {
      const response = await fetch('/api/exercises/remaining', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch remaining exercises');
      const data = await response.json();
      setRemaining(data);
    } catch (error) {
      console.error('Error fetching remaining exercises:', error);
    }
  };

  // Initial fetch of remaining exercises
  useEffect(() => {
    fetchRemaining();
  }, []);

  // Refetch remaining exercises when feedback is received
  useEffect(() => {
    if (feedback) {
      fetchRemaining();
    }
  }, [feedback]);

  const handlePartSelect = (part) => {
    if (isConnected) {
      disconnect();
    }
    stopAllAudio();
    setCurrentPart(part);
    setSelectedTopic(null);
    setFeedback(null);
    setIsPreparing(false);
  };

  const handleStartPreparation = async () => {
    await getCueCard(selectedTopic);
    setIsPreparing(true);
  };

  const handleEndPreparation = async () => {
    connect(currentPart.toString(), selectedTopic);
  };

  // Reset preparation for part 2 when connected to the stream
  useEffect(() => {
    if (currentPart === 2 && isPreparing && isConnected) {
      setIsPreparing(false);
    }
  }, [isConnected, currentPart, isPreparing]);

  const handleStartExercise = async () => {
    if (!selectedTopic) {
      // Maybe show an alert or tooltip asking to select a topic first
      return;
    }
    
    // Stop any playing audio
    stopAllAudio();
    
    setFeedback(null);
    if (currentPart === 2) {
      await handleStartPreparation();
    } else {
      connect(currentPart.toString(), selectedTopic);
    }
  };

  return (
    <div className="container mt-4">
      {showSuccessMessage && (
        <div className="alert alert-success alert-dismissible fade show text-center mb-4" role="alert">
          {t('upgrade_thank_you')}
          <button 
            type="button" 
            className="btn-close" 
            onClick={() => setShowSuccessMessage(false)}
            aria-label="Close"
          ></button>
        </div>
      )}
      
      <div className="container mt-5 px-4">
        <div className="text-center mb-5">
          <img
            src={logo}
            alt="Logo"
            className="img-fluid"
            style={{
              height: "60px",
              width: "auto",
              objectFit: "contain",
            }}
          />
          <div className="mt-3 fs-3" style={{ color: "#aaa" }}>{t('subtitle')}</div>
        </div>
        
        <PartIndicator 
          currentPart={currentPart}
          onPartSelect={handlePartSelect}
          remaining={remaining}
        />
        
        <TopicSelector
          part={currentPart}
          selectedTopic={selectedTopic}
          onSelect={(topic) => {
            if (isConnected) {
              disconnect();
            }
            stopAllAudio();
            setSelectedTopic(topic);
            setFeedback(null);
            setIsPreparing(false);
          }}
        />
        <Instructions 
          part={currentPart} 
          topic={selectedTopic} 
        />

        <div className="row justify-content-center">
          <div className="col-md-9">
            <div className="text-center mb-4">
              {feedbackError && <Error message={feedbackError} />}
              <div className="d-flex flex-column justify-content-center mb-4">
                {!isPreparing && !isConnected && (
                  remaining && remaining[`part${currentPart}`] === 0 ? (
                    <UpgradeButton currentPart={currentPart} />
                  ) : (
                    <StartButton onClick={handleStartExercise} />
                  )
                )}
                {isConnected && (
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <button
                      onClick={() => {
                        requestFeedback();
                      }}
                      className="btn px-4 py-3 w-100 btn-danger shadow"
                      style={{
                        color: "white",
                        borderRadius: "30px",
                        transition: "all 0.3s ease",
                        maxWidth: "300px",
                        fontSize: "1.1rem",
                      }}
                      disabled={isWaitingForFeedback}
                    >
                      {isWaitingForFeedback ? (
                        <LoadingText text={t('analysing_response')} />
                      ) : (
                        t('end')
                      )}
                    </button>
                  </div>
                )}
                {currentPart === 2 && isPreparing && (
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <button
                      onClick={handleEndPreparation}
                      className="btn px-4 py-3 btn-success shadow w-100"
                      style={{
                        color: "white",
                        borderRadius: "30px",
                        transition: "all 0.3s ease",
                        maxWidth: "300px",
                        fontSize: "1.1rem",
                      }}
                    >
                      {t('end_preparation')} (<Timer duration={60} onComplete={handleEndPreparation} />)
                    </button>
                  </div>
                )}
                <ReportIssueButton part={currentPart} topic={selectedTopic} />
              </div>
              {currentPart === 2 && (isPreparing || isConnected) && (
                <div className="row">
                  <div className="col-md-8 mb-3 mb-md-0">
                    {cueCard && cueCard.topic && <CueCard {...cueCard} />}
                  </div>
                  <div className="col-md-4">
                    <NotePad />
                  </div>
                </div>
              )}
            </div>

            {feedback && (
              <div className="mt-4 mb-5">
                <Feedback 
                  feedback={feedback} 
                  pronunciations={pronunciations}
                  conversationAudioUrl={conversationAudioUrl}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
