import React from 'react';

const Footer = () => {
  return (
    <footer className="my-4 text-center" style={{ fontSize: '0.9rem', color: '#999' }}>
      © 2025 Fluentious
    </footer>
  );
};

export default Footer;
